<nav-bar-menu>
  <div class="grid-x">
    <div class="sub-menu sub-menu-left hide-for-small-only medium-6 animated slideInDown hide">
       <div class="sub-menu-hero-container">
        <div class="sub-menu-hero"></div>
      </div>
    </div>
    <div class="sub-menu sub-menu-right small-12 medium-6 animated slideInUp hide">
      <ul class="sub-menu-nav-list">
   <!--      <li><a href="wedding.html">The Wedding</a></li>
        <li><a href="accomodations.html">Accomodations</a></li> -->
        <li><a href="engagement-photos.html">The Engagement Photos</a></li>
        <li><a href="engagement.html">The Engagement Party</a></li>
        <li><a href="proposal.html">The Proposal</a></li>
<!--         <li><a href="#" onclick={ resetMsg }>COVID-19</a></li>
        <li><a href="registry.html">Registry & Gifts</a></li> -->
      </ul>

    </div>
  </div>
  <script>
    export default {
      resetMsg() {
        localStorage.removeItem('announcementMsg');
        document.location.href='/';
      }
    }
  </script>
  <style>
    .sub-menu {
      position: fixed;
      height: 100%;
      z-index: 999;
      overflow: hidden;
    }
    .sub-menu-left {
      left: 0;
      background-color: #000;
    }
    .sub-menu-right {
      right: 0;
      background-color: #fff;
    }
    .sub-menu-nav-list {
      position: absolute;
      top: 50%;
      left: 10%;
      padding: 0;
      margin: -62px 0 0;
      width: 300px;
      height: 124px;
    }
    /*.sub-menu-nav-list li:last-child {
      margin-top: 20px;
    }*/
    .sub-menu-nav-list a {
      color: #000;
      transition-property: opacity, filter, -webkit-filter;
      transition-duration: 0.5s;
      transition-timing-function: linear;
    }
    .sub-menu-nav-list a:hover {
      opacity: .50;
    }
    .sub-menu-nav-list a:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: relative;
      background: -moz-linear-gradient(left, #6e6f71 0%,#808184 25%,#a6a8ac 50%,#6e6f71 75%,#595a5c 100%);
      background: -webkit-linear-gradient(left, #6e6f71 0%,#808184 25%,#a6a8ac 50%,#6e6f71 75%,#595a5c 100%);
      background: linear-gradient(to right, #6e6f71 0%,#808184 25%,#a6a8ac 50%,#6e6f71 75%,#595a5c 100%);
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: left center;
      transform-origin: left center;
      -webkit-transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
    .sub-menu-nav-list a:hover:after {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: .50;
    }
    .sub-menu-hero-container {
      width: 100%;
      height: 100%;
      background-color: #000;
    }
    .sub-menu-hero {
      width: 100%;
      height: 100%;
      background: url(images/img_nav.jpg) 0 0 no-repeat;
      background-size: cover;
    }
  </style>
</nav-bar-menu>