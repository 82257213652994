import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const analytics = Analytics({
  app: 'shriyaandrodney',
  version: 1,
  plugins: [
    googleAnalytics({
      trackingId: 'UA-156443552-1',
    })
  ]
});


export default analytics;