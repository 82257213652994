<app>
  <nav-bar></nav-bar>
  <div class="grid-x grid-padding-x grid-padding-y main-container">
    <div class="cell welcome"></div>
    <nav-bar-menu></nav-bar-menu>
  </div>
  <script>
    import NavBar from './nav-bar.riot';
    import NavBarMenu from './nav-bar-menu.riot';

    import moment from 'moment-timezone';

    export default {
      onBeforeMount(props, state) {
        // initial state
        this.state = {
          items: props.items,
          text: ''
        }
      },
      onMounted() {
      },
      components: {
        NavBar,
        NavBarMenu
      }
    }
  </script>
  <style>
  .welcome {
    position: fixed;
    width: 100%;
    height: 100%;
    /*background: url(images/new/img_8440.jpg) center center no-repeat;*/
    background: url(images/img_6899.jpg) center center no-repeat;
    background-size: cover;
  }

  /* small */
  @media screen and (max-width: 39.9375em) {
   .welcome {
      position: fixed;
      width: 100%;
      height: 100%;
      background: url(images/img_8440.jpg) center center no-repeat;
/*      background: url(images/new/img_9999_sml.jpg) center 0 no-repeat;*/
      background-size: cover;
    }
  }
  </style>
</app>