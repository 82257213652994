import * as riot from 'riot'
import App from './riot/app.riot'

import './styles/app.scss';
import 'animate.css/animate.min.css';
import './styles/global.css';

import Foundation from 'foundation-sites';

const mountApp = riot.component(App);

const app = mountApp(
  document.getElementById('app')
);