const fnAddImageToDom = (arrLoadedImages) => {
  const body = document.querySelector('body');
  const imagesContainer = document.createElement('div');
  imagesContainer.setAttribute('class', `prefetch-image-container_${Math.random()}`);
  imagesContainer.style.width = 0;
  imagesContainer.style.height = 0;
  imagesContainer.style.overflow = 'hidden';
  imagesContainer.style.opacity = 0;
  imagesContainer.style.display = 'none';
  arrLoadedImages.forEach(function(img) {
    imagesContainer.appendChild(img);
  });
  body.appendChild(imagesContainer);
}

const fnImagePreloader = (elem) => {
  let arrLoadedImages = [];
  let imgArr = [];

  if(elem.length === 0){
    return false;
  }

  if(Array.isArray(elem)) {
    imgArr = elem;
  }else{
    imgArr.push(elem);
  }
    
  imgArr.forEach((asset) => {
    const img = new Image();

    img.onload = function() {
      //console.log(`src: ${asset}`);
    };
    img.onerror = function() {
      console.error(`[prefetch-image]: "${asset}" failed`);
    };

    img.src = asset;
    arrLoadedImages.push(img);
  });

  fnAddImageToDom(arrLoadedImages); 
}

export default fnImagePreloader;