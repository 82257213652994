<nav-bar>
   <div class="grid-x">
    <div class="cell large-auto clearfix">
      <ul class="menu align-left">
        <li>
          <button class="hamburger hamburger--spin" type="button" onclick={ hamburger }>
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </li>
        <li>
           <a class="logo" href="/#" onclick={ hamburgerReset }></a>
           <div class="countdown"></div>
        </li>
      </ul>
    </div>
  </div>
  <script>
    import moment from 'moment-timezone';
    import Preloader from '../preloader.js';
    import analytics from '../analytics.js';
  
    export default {
      onBeforeMount() {  
        if(location.hostname==='shriyaandrodney.com'||location.hostname==='rodneyandshriya.com'){
          analytics.page();
        };
      },
      onMounted() {
        let eventTime = moment.tz('2021-05-22 12:00:00 PM', 'YYYY-MM-DD hh:mm:ss a', 'America/New_York');
        let currentTime = moment().tz('America/New_York');
        let duration = moment.duration(eventTime.diff(currentTime));
        let month2days = moment.tz('2021-05-22 12:00:00 PM', 'YYYY-MM-DD hh:mm:ss a', 'America/New_York').diff(moment().tz('America/New_York'), 'days');
        const $countdown = document.querySelectorAll('.countdown')[0];
        
         if(duration < 1){
          // console.log( duration._data.months * -1 + ' months & ' + duration._data.days * -1 + ' days ago');
          // console.log(eventTime.from());
          // if(duration._data.months===0){
          //   $countdown.innerHTML = duration._data.days * -1 + ' days ago';
          // }else{
          //   $countdown.innerHTML = duration._data.months * -1 + ' months & ' + duration._data.days * -1 + ' days ago';
          // }
          $countdown.innerHTML = eventTime.from();
        }else{
         // console.log( 'in ' + duration._data.months + ' months & ' + duration._data.days + ' days', month2days);
         // console.log(eventTime.fromNow());
         // if(duration._data.months===0){
         //  $countdown.innerHTML = 'in ' + duration._data.days + ' days', month2days;
         // }else{
         //  $countdown.innerHTML = 'in ' + duration._data.months + ' months & ' + duration._data.days + ' days', month2days;
         // }
          $countdown.innerHTML = eventTime.fromNow();
        }
        
        const arrAssets = [
          'images/engagement.jpg',
          'images/engagement-2.jpg',
          'images/engagement-3.jpg',
          'images/engagement-4.jpg',
          'images/engagement-5.jpg',
          'images/engagement-6.jpg',
          'images/engagement-7.jpg',
          'images/proposal.jpg',
          'images/proposal-2.jpg',
          'images/proposal-3.jpg',
          'images/rooftop-hero.jpg',
          'images/photoshoot-5.jpg',
          'images/photoshoot-7.jpg',
          'images/photoshoot-9.jpg',
          'images/pleasantdale.jpg',
          'images/hilton.jpg',
          'images/logo.svg',
          'images/img_nav.jpg',
          'images/img_9999.jpg',
          'images/img_9999_sml.jpg',
          'images/img_6035.jpg',
          'images/img_6037.jpg',
          'images/img_6052.jpg',
          'images/img_6869.jpg',
          'images/img_6899.jpg',
          'images/img_8440.jpg'
        ];

        Preloader(arrAssets);     
      },
      closeMsg(){
        // localStorage.setItem('announcementMsg', true);
        // document.querySelectorAll('announcement')[0].classList.add('hide');
      },
      hamburger(e) {
        e.currentTarget.classList.toggle("is-active");
        //document.querySelectorAll('.logo')[0].classList.toggle("is-active");
        const arrSubMenu = [...document.querySelectorAll('.sub-menu')];
        arrSubMenu.map(x => {x.classList.toggle('show'); x.classList.toggle('hide')});
        this.closeMsg();
      },
      hamburgerReset() {
        document.querySelectorAll('.hamburger')[0].classList.remove("is-active");
        //document.querySelectorAll('.logo')[0].classList.toggle("is-active");
        const arrSubMenu = [...document.querySelectorAll('.sub-menu')];
        arrSubMenu.map(x => {x.classList.remove('show'); x.classList.add('hide')});
        this.closeMsg();
      }
    }
  </script>
  <style>
    .menu {
      position: fixed; /* Set the navbar to fixed position */
      top: 0; /* Position the navbar at the top of the page */
      width: 100%; 
      height: 60px;
      background: #000;
      /*border-bottom: 2px solid #fff;*/
      z-index: 9999;
      overflow: hidden;
    }

    .logo {
      width: 55px;
      height: 55px;
/*      background: url(images/logo.svg) center center no-repeat;
      background-size: 100% auto;*/
      -webkit-mask: url(images/logo.svg) no-repeat center center;
      mask: url(images/logo.svg) no-repeat center center;
      -webkit-mask-size: 100% auto;
      mask-size: 100% auto;
      background-color: #86764E;
      background-color: #fff;
      margin-top: 2px;

      transition-property: opacity, filter, -webkit-filter;
      transition-duration: 0.15s;
      transition-timing-function: linear;
    }
    .logo.is-active,
    .logo:hover {
      opacity: 0.7;
    }

    .menu li {
      height: 60px;
      padding: 0;
    }
    .hamburger {
      height: 60px;
      margin-top: 3px;
    }

    .hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after{
      height: 3px;
      border-radius: 3px;
    }

    .countdown {
      position: absolute;
      top: 0px;
      right: 10px;
      font-family: 'Arsenal';
      font-weight: 500;
      color: #fff;
      text-align: right;
      line-height: 60px;
    }
    /* small */
    @media screen and (max-width: 39.9375em) {
      .countdown {
       font-size: 0.8em;
      }
    }
  </style>
</nav-bar>